
import React from "react"
import { Link } from "gatsby"
import footerStyles from "./footer.module.scss"
import logo from '../images/studio-legale-pacchioni--w.svg'

const Footer = () => (
  <footer className={footerStyles.footer}>
    <div className={footerStyles.innerfooter}>
        <img src={logo} alt=""/>
        <nav>
        <ul className={footerStyles.list}>
          <li className={footerStyles.listitem}>
            <Link to="/chi-siamo" >
              Chi siamo
            </Link>  
          </li>
          <li className={footerStyles.listitem}>
            <Link to="/aree-di-attivita">
              Aree di attività
            </Link>  
          </li>
          <li className={footerStyles.listitem}>
            <Link to="/contatti">
              Contatti
            </Link>  
          </li>
        </ul>
        <label className={footerStyles.label}> Made by <a href="https://www.bnkr.it/it/">Bunker</a> </label> 
      </nav>
      </div>
  </footer>
)
/*
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
*/

export default Footer

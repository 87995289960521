import { Link } from "gatsby"
import React from "react"

import logo from '../images/studio-legale-pacchioni.svg'
import headerStyles from './header.module.scss'

const Header = ({ siteTitle }) => (
  <header>
    <div className={headerStyles.bg}>
        <div className={headerStyles.innerbg}></div>
    </div>
    <div className={headerStyles.header}>
      <div className={headerStyles.container}>
        <Link to="/">
          <img src={logo} alt={siteTitle}/>
        </Link>
      <nav>
        <ul className={headerStyles.list}>
          <li>
            <Link to="/" activeClassName={headerStyles.activeNavItem}>
              Home
            </Link>  
          </li>
          <li>
            <Link to="/chi-siamo" activeClassName={headerStyles.activeNavItem}>
              Chi siamo
            </Link>  
          </li>
          <li>
            <Link to="/aree-di-attivita" activeClassName={headerStyles.activeNavItem}>
              Aree di attività
            </Link>  
          </li>
          <li>
            <Link to="/contatti" activeClassName={headerStyles.activeNavItem}>
              Contatti
            </Link>  
          </li>
        </ul>
      </nav>
    </div>
    </div>
  </header>
)

export default Header

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/micol/Repository/studiolegalepacchioni/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aree-di-attivita-js": () => import("/Users/micol/Repository/studiolegalepacchioni/src/pages/aree-di-attivita.js" /* webpackChunkName: "component---src-pages-aree-di-attivita-js" */),
  "component---src-pages-chi-siamo-js": () => import("/Users/micol/Repository/studiolegalepacchioni/src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("/Users/micol/Repository/studiolegalepacchioni/src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("/Users/micol/Repository/studiolegalepacchioni/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/micol/Repository/studiolegalepacchioni/.cache/data.json")


import React from "react"
import { Helmet } from "react-helmet"
import Transition from "../components/transition"
import Header from "../components/header"
import Footer from "../components/footer"
import image from "../images/attivita.jpg"

import "./layout.scss"


const TemplateWrapper = ({ children, location }) => (
  <div>
    <Helmet
      title={"Pacchioni Studio Legale"}
      meta={[
        { name: `description`, content: `Pacchioni Studio Legale` },
        { name: `keywords`, content: `studio, pacchioni, modena legale, something` },
      ]}
      link={[
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` },
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` },
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` },
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` },
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` },
        { rel: "icon", type: "image/jpg", sizes: "16x16", href: `${image}` }
      ]}
    />




    <Header />
    <div className="outher-bg">
      <div className="bg">
        <div className="inner-bg"></div>
      </div>
    </div>

    <div className="main">
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Transition location={location}>
                        {children}
                    </Transition>
                </div>
            </div>
        </div>
    </div>

    <Footer />
  </div>
)

export default TemplateWrapper
